'use client'

import Button from '@components/interaction/button'
import HeaderLink from './link'
import HomeLink from './home-link'
/* eslint-disable jsx-a11y/label-has-associated-control */
import styles from './header.module.css'
import { useHeader } from './header-provider'

export default function Header() {
  const { setOpen, isOpen, isDark } = useHeader()

  const closeMenu = () => {
    const menuBtn = document.querySelector<HTMLInputElement>('#menu-btn')
    if (menuBtn != null) {
      menuBtn.checked = false
    }

    setOpen(false)
  }

  return (
    <header
      className={`absolute z-50 w-screen justify-center bg-transparent text-base lg:flex`}
    >
      <div className='max-width flex lg:items-center'>
        <div className={`${styles['top-container']} flex justify-between`}>
          <HomeLink />
          <div className='flex-1' />
          <input
            className={`${styles.menuBtn} hidden h-10 appearance-none hover:bg-neutral-900 `}
            type='checkbox'
            id='menu-btn'
            onChange={(event) => {
              setOpen(event.target.checked)
            }}
          />
          <label
            className={`${styles.menuIcon} relative  inline-block select-none px-5 py-7 lg:hidden`}
            data-testid='menu-open'
            htmlFor='menu-btn'
          >
            <span
              className={`${styles.navicon} transition-all duration-300 ${
                isDark && !isOpen
                  ? 'bg-white before:bg-white after:bg-white'
                  : 'bg-black before:bg-black after:bg-black'
              }`}
            />
          </label>
        </div>
        <ul
          className={`${styles.menu} flex h-screen w-full list-none items-center justify-center space-y-4 text-zinc-900 md:space-y-0 lg:h-full`}
        >
          <li>
            <HeaderLink
              href='/services'
              title='SERVICES'
              aria-label='Services'
            />
          </li>
          <li>
            <HeaderLink href='/about' title='ABOUT' aria-label='About' />
          </li>
          <li>
            <HeaderLink
              href='/insights'
              title='INSIGHTS'
              aria-label='insights'
            />
          </li>
          <li>
            <HeaderLink href='/join' title='JOIN' aria-label='Join' />
          </li>
          <li className='hidden flex-1 lg:block' />
          <li className='self-center'>
            <Button
              variant='outlined'
              colour={isDark && !isOpen ? 'secondary-contrast' : 'secondary'}
              data-testid='header-contact-button'
              onClick={closeMenu}
              href='/contact'
              aria-label='Contact'
              size='sm'
            >
              CONTACT
            </Button>
          </li>
          <li className='h-40 lg:hidden' />
        </ul>
      </div>
    </header>
  )
}
